exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-2021-07-atlassian-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/markdown/posts/2021-07-atlassian.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-2021-07-atlassian-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-2025-08-maliit-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/markdown/posts/2025-08-maliit.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-2025-08-maliit-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-blog-launch-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/markdown/posts/blog-launch.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-blog-launch-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-path-to-worldskills-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/markdown/posts/path-to-worldskills.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-path-to-worldskills-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-tafe-student-year-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/markdown/posts/tafe-student-year.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-tafe-student-year-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-trainboard-tfnsw-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/markdown/posts/trainboard-tfnsw.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-trainboard-tfnsw-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-worldskills-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/src/markdown/posts/worldskills.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-markdown-posts-worldskills-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-about-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=/opt/build/repo/src/markdown/pages/about.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-about-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-trainboard-privacy-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=/opt/build/repo/src/markdown/pages/trainboard/privacy.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-trainboard-privacy-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-tripwatch-help-00-index-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=/opt/build/repo/src/markdown/pages/tripwatch/help/00-index.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-tripwatch-help-00-index-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-tripwatch-help-01-getting-started-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=/opt/build/repo/src/markdown/pages/tripwatch/help/01-getting-started.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-tripwatch-help-01-getting-started-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-tripwatch-help-02-trip-planning-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=/opt/build/repo/src/markdown/pages/tripwatch/help/02-trip-planning.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-tripwatch-help-02-trip-planning-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-tripwatch-privacy-mdx": () => import("./../../../src/templates/page-template.tsx?__contentFilePath=/opt/build/repo/src/markdown/pages/tripwatch/privacy.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-markdown-pages-tripwatch-privacy-mdx" */)
}

